<template>
    <v-card>
        <v-card-title>
            <v-row class="wordbreak-normal" align="center">
                <v-col>
                    {{ info.name }}
                </v-col>
                <v-col cols="auto">
                    <v-img :src="info.image" width="3em" contain eager />
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-subtitle>
            <v-chip 
                v-if="!!info.version"
                small
                class="mr-1"
            >
                v{{ info.version }}
            </v-chip>

            <v-chip
                v-if="!!info.claimPrefix"
                small
                :color="canAccess ? 'success' : 'error'"
                class="mr-1"
            >
                {{ canAccess ? 'Accès' : 'Accès' }}
                <v-icon right small>{{ canAccess ? 'mdi-check' : 'mdi-cancel' }}</v-icon>
            </v-chip>
            <v-chip v-if="!!info.building" small color="warning" class="mt-1">
                En construction <v-icon right small>mdi-wrench</v-icon>
            </v-chip>
        </v-card-subtitle>

        <v-card-text v-if="!info.claimPrefix || !!canAccess">
            {{ info.details }}
        </v-card-text>
        <!-- <div v-if="info.updates">
            <v-divider/>
            <v-expansion-panels flat tile>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Versions
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <li>hi</li>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div> -->
    </v-card>
</template>

<script>
export default {
    name: 'mAboutCard',
    props: {
        info: { type: Object, required: true },
    },
    data() { return {

    }},
    computed: {
        canAccess() {
            const claims = this.$store.state.user.data.claims;
            let claimsArr = Object.entries(claims);

            claimsArr = claimsArr.filter(claim => claim[1] === true);
            claimsArr = claimsArr.map(claim => (claim[0].match(/^(\w+_)/) || [] )[0]);
            return claims.superAdmin || claims.superMod || claimsArr.includes(this.info.claimPrefix) ;
        }
    }
}
</script>

<style>

</style>