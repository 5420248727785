<template>
    <v-container>
        <h1 class="text-h4">Application</h1>
        <v-row>
            <v-col v-for="info in appInfo" :key="info.name"
                cols="12" sm="6" md="4" lg="3"
            >
                <m-about-card :info="info" />
            </v-col>
        </v-row>

        <v-divider class="my-8" />

        <h1 class="text-h4">Modules</h1>
        <v-row>
            <v-col v-for="info in moduleInfo" :key="info.name"
                cols="12" sm="6" md="4" lg="3"
            >
                <m-about-card :info="info" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import mAboutCard from '../components/about/infoCard.vue';

export default {
    name: 'mAbout',
    components: { mAboutCard },
    data() { return {
        appInfo: [
            { 
                name: 'focus', image: '/img/logo-app.svg', version: process.env.VUE_APP_VERSION, 
                details: "Application sur-mesure pour le SDIS Coeur de Lavaux" 
            },
            { name: 'SDIS Coeur de Lavaux', image: '/img/logo-a.svg', details: "Le plus beau SDIS du canton" },
            { name: 'MSassen', image: '/img/logo-msassen.jpeg', details: "Créateur de l'application" },
        ],
        moduleInfo: [
            // { 
            //     name: 'Checklists', image: '/img/module-logos/checklists.svg', version: '0', building: true,
            //     details: "Checklists de l'équipe matériel", 
            //     claimPrefix: 'checklists_',
            // },
            { 
                name: 'Cylindres', image: '/img/module-logos/keytubes.png', version: process.env.VUE_APP_VERSION_KEYTUBES, building: true,
                details: "Collection des cylindres feu et dossiers d'intervention sur notre secteur", 
                claimPrefix: 'keytubes_',
            },
            { 
                name: 'Equipement', image: '/img/module-logos/gear.svg', version: process.env.VUE_APP_VERSION_GEAR, 
                details: "Gestion des équipements personnels", 
                claimPrefix: 'gear_',
            },
            { 
                name: 'Membres', image: '/img/module-logos/users.svg', version: process.env.VUE_APP_VERSION_USERS,
                details: "Gestion des membres sur l'application", 
                claimPrefix: 'users_',
            },
        ],
    }},
    mounted() {
    },
}
</script>

<style>

</style>